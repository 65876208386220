import React from 'react';
import Milestone from '../components/homepage/milestone';
import Features from '../components/homepage/features';
import Products from '../components/homepage/products';
import GDPR from '../components/gdpr';
import FeatureSwiper from '../components/homepage/featureSwiper';
import Integrated from '../components/homepage/integrated';
import Banner from '../components/homepage/banner';
import Layout from '../components/layout';
import Partners from '../components/homepage/partners';
import News from '../components/homepage/news';
import SEO from '../components/seo';
import { AppContext } from '../context/index';

const homeSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Blocto',
  url: 'https://www.blocto.io',
  logo: '',
  sameAs: ['https://twitter.com/BloctoApp', 'https://www.linkedin.com/company/portto/'],
});

const IndexPage = () => (
  <AppContext.Consumer>
    {() => (
      <Layout>
        <SEO
          title="Blocto | Smooth, secure, gas-saving account abstraction wallet"
          description="Simplifies your Web3 experience with Blocto account abstraction wallet. Reduce gas fees, robust security and seamless asset management across blockchains"
          script={homeSchema}
          scriptType="application/ld+json"
        />
        <Banner />
        <News />
        <Milestone />
        <Features />
        <Products />
        <Partners />
        <FeatureSwiper />
        <Integrated />
        <GDPR />
      </Layout>
    )}
  </AppContext.Consumer>
);

export default IndexPage;
