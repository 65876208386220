/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Flex, Image, Text, Box, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import I18n from '../I18n';
import 'swiper/css';
import 'swiper/css/pagination';
import Button from '../button';
import PageArrow from '../svgIcon/pageArrow';
import gtagEvent from '../../utils/gtag';
import theBlockMobileImg from '../../../static/new_images/feature/the-block-240.png';
import theBlock308Img from '../../../static/new_images/feature/the-block-308.png';
import theBlockImg from '../../../static/new_images/feature/the-block.png';
import prNewswireImg from '../../../static/new_images/feature/pr-newswire.png';
import prNewswire308Img from '../../../static/new_images/feature/pr-newswire-308.png';
import prNewswireMobileImg from '../../../static/new_images/feature/pr-newswire-240.png';
import nasdaqMobileImg from '../../../static/new_images/feature/nasdaq-240.png';
import nasdaq308Img from '../../../static/new_images/feature/nasdaq-308.png';
import nasdaqImg from '../../../static/new_images/feature/nasdaq.png';
import yahooFinanceMobileImg from '../../../static/new_images/feature/yahooFinance-240.png';
import yahooFinance308Img from '../../../static/new_images/feature/yahooFinance-308.png';
import yahooFinanceImg from '../../../static/new_images/feature/yahooFinance.png';
import cointelegraphMobileImg from '../../../static/new_images/feature/cointelegraph-240.png';
import cointelegraph308Img from '../../../static/new_images/feature/cointelegraph-308.png';
import cointelegraphImg from '../../../static/new_images/feature/cointelegraph.png';
import CustomLink from '../customLink';

const FeatureCardInfo = [
  {
    icon: theBlockImg,
    iconTablet: theBlock308Img,
    iconMobile: theBlockMobileImg,
    title: 'The Block',
    content: 'Blocto crypto wallet closes Series A round at an $80 million valuation',
    date: 'Feb 22, 2023',
    link: 'https://www.theblock.co/post/213890/blocto-crypto-wallet-closes-series-a-round-at-an-80-million-valuation',
  },
  {
    icon: cointelegraphImg,
    iconTablet: cointelegraph308Img,
    iconMobile: cointelegraphMobileImg,
    title: 'Cointelegraph',
    content: 'Crypto wallet Blocto receives funding from Mark Cuban',
    date: 'Nov 11, 2021',
    link: 'https://cointelegraph.com/press-releases/crypto-wallet-blocto-receives-funding-from-mark-cuban',
  },
  {
    icon: prNewswireImg,
    iconTablet: prNewswire308Img,
    iconMobile: prNewswireMobileImg,
    title: 'Benzinga',
    content:
      'Blocto Launches $1M Polygon Ecosystem Accelerator to Supercharge Seamless Web3 Onboarding',
    date: 'Jul 21, 2023',
    link: 'https://www.benzinga.com/pressreleases/23/07/n33322067/blocto-launches-1m-polygon-ecosystem-accelerator-to-supercharge-seamless-web3-onboarding',
  },
  {
    icon: yahooFinanceImg,
    iconTablet: yahooFinance308Img,
    iconMobile: yahooFinanceMobileImg,
    title: 'Yahoo! Finance',
    content: 'Crypto Wallet Blocto Partners With Yahoo Taiwan to Launch NFT Store',
    date: 'Mar 17, 2022',
    link: 'https://finance.yahoo.com/news/crypto-wallet-blocto-partners-yahoo-030030342.html',
  },
  {
    icon: nasdaqImg,
    iconTablet: nasdaq308Img,
    iconMobile: nasdaqMobileImg,
    title: 'Nasdaq',
    content:
      'Crypto For The Masses: Breaking Down Barriers to Entry With User-Friendly Onboarding Portals',
    date: 'Mar 17, 2023',
    link: 'https://www.nasdaq.com/articles/crypto-for-the-masses%3A-breaking-down-barriers-to-entry-with-user-friendly-onboarding',
  },
];

const FeatureSwiper = () => {
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const isMOBILE = useBreakpointValue({ base: true, md: false, lg: false });
  const iconName = useBreakpointValue({ base: 'iconMobile', md: 'iconTablet', lg: 'icon' });
  const [isDESKTOP] = useMediaQuery('(min-width: 1200px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  const prev = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'feature_article_block',
    });
    swiper.slideTo(0);
  };
  const nexto = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'feature_article_block',
    });
    swiper.slideTo(3);
  };

  const swiperProps = isDESKTOP
    ? { slidesPerView: 3 }
    : {
        width: '276',
        breakpoints: {
          768: {
            width: 363,
          },
        },
      };

  return (
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '40px', md: '80px', lg: '120px' }}
      pb={{ base: '40px', md: '80px', lg: '120px' }}
      bg="background.secondary"
      borderRadius={{ base: '30px', lg: '60px' }}
    >
      <Box w="100%" maxW="1200px" m="0 auto">
        <Text
          mb={{ base: '16px', md: '40px', lg: '56px' }}
          color="icon.text"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
        >
          <I18n id="blocto.index.featuredIn" />
        </Text>

        <Box w="100%">
          <Swiper
            spaceBetween={isMOBILE ? 12 : 24}
            style={{
              paddingBottom: '24px',
            }}
            onSwiper={(s) => {
              setSwiper(s);
            }}
            onSlideChange={(e) => {
              setSwiperIndex(e.realIndex);
            }}
            {...swiperProps}
          >
            {FeatureCardInfo.map((item) => (
              <SwiperSlide key={item.content}>
                <CustomLink
                  noHoverStyle
                  to={item.link}
                  isExternal
                  gtag={{
                    event: 'bloctoweb_click_link',
                    properties: {
                      location: 'feature_article_block',
                      topic_title: item.title,
                      link_url: item.link,
                      link_domain: new URL(item.link).host,
                    },
                  }}
                  display="flex"
                  flexDirection="column"
                  bg="background.primary"
                  borderRadius="32px"
                  boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.05)"
                  overflow="hidden"
                  h={{ base: '376px', md: '424px' }}
                >
                  <Image borderRadius="0 0 44px 44px" w="100%" src={item[iconName]} maxH="180px" />
                  <Flex
                    flex="1"
                    flexDirection="column"
                    justifyContent="space-between"
                    px="space.m"
                    py="space.xl"
                  >
                    <Box pt="space.4xs" px="space.m">
                      <Text
                        color="icon.text"
                        fontSize={{ base: '20px', md: '25px' }}
                        fontWeight="weight.m"
                        lineHeight="120%"
                        mb="space.m"
                      >
                        {item.title}
                      </Text>
                      <Text
                        display="-webkit-box"
                        color="icon.text"
                        fontSize={{ base: 'size.body.3', md: 'size.body.2' }}
                        lineHeight={{ base: '150%', md: '130%' }}
                        opacity="0.5"
                        overflow="hidden"
                        sx={{
                          '-webkit-line-clamp': '2',
                          '-webkit-box-orient': 'vertical',
                        }}
                      >
                        {item.content}
                      </Text>
                    </Box>

                    <Flex align="center" justify="space-between" pl="space.m">
                      <Text color="icon.text" fontSize="13px" lineHeight="150%">
                        {item.date}
                      </Text>
                      <Button
                        variant="plain"
                        withArrowIcon
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight="130%"
                      >
                        <I18n id="blocto.common.more" />
                      </Button>
                    </Flex>
                  </Flex>
                </CustomLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Flex
          w="100%"
          justify="flex-end"
          p={{ base: '0 0 40px 0', sm: '0 0 64px 64px', lg: '0' }}
          display={{ base: 'none', lg: 'flex' }}
          maxW={{ md: '1200px' }}
          m={{ md: '0 auto' }}
        >
          <Button
            mode="icon"
            variant="icon"
            mr="space.m"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={prev}
            disabled={swiperIndex === 0}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 0 ? '#BFC2C6' : undefined} transform="scaleX(-1)" />
          </Button>
          <Button
            mode="icon"
            variant="icon"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={nexto}
            disabled={swiperIndex === 2}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 2 ? '#BFC2C6' : undefined} />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default FeatureSwiper;
