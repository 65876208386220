import React from 'react';
import I18n from '../../I18n';
import downloadFnc from '../../../utils/download';
import gtagEvent from '../../../utils/gtag';

const CTA_DATA = [
  {
    bg: '#00ADFF',
    title: <I18n id="blocto.cta.integratedWith.title" />,
    content: <I18n id="blocto.cta.integratedWith.content" />,
    buttonText: <I18n id="blocto.cta.integratedWith.button" />,
    onClick: () => {
      gtagEvent('bloctoweb_click_sdkpage', { location: 'integration_block' });
      window.location.href = '/sdk';
    },
  },
  {
    bg: 'icon.text',
    title: <I18n id="blocto.cta.getWallet.title" />,
    content: <I18n id="blocto.cta.getWallet.content" />,
    buttonText: <I18n id="blocto.cta.getWallet.button" />,
    onClick: () => downloadFnc('integration_block'),
  },
];

export default CTA_DATA;
