import yahooImg from '../../../../static/new_images/partners/yahoo.png';
import topshotImg from '../../../../static/new_images/partners/topshot.png';
import markImg from '../../../../static/new_images/partners/mark.png';
import motoImg from '../../../../static/new_images/partners/moto.png';
import fiveHundredImg from '../../../../static/new_images/partners/500.png';
import kevinImg from '../../../../static/new_images/partners/kevin.png';
import animocaImg from '../../../../static/new_images/partners/animoca.png';
import ipxImg from '../../../../static/new_images/partners/ipx.png';

import yahooImgTablet from '../../../../static/new_images/partners/tablet/t-yahoo.png';
import topshotImgTablet from '../../../../static/new_images/partners/tablet/t-topshot.png';
import markImgTablet from '../../../../static/new_images/partners/tablet/t-mark.png';
import motoImgTablet from '../../../../static/new_images/partners/tablet/t-moto.png';
import fiveHundredImgTablet from '../../../../static/new_images/partners/tablet/t-500.png';
import kevinImgTablet from '../../../../static/new_images/partners/tablet/t-kevin.png';
import animocaImgTablet from '../../../../static/new_images/partners/tablet/t-animoca.png';
import ipxImgTablet from '../../../../static/new_images/partners/tablet/t-ipx.png';

import yahooImgMobile from '../../../../static/new_images/partners/mobile/m-yahoo.png';
import topshotImgMobile from '../../../../static/new_images/partners/mobile/m-topshot.png';
import markImgMobile from '../../../../static/new_images/partners/mobile/m-mark.png';
import motoImgMobile from '../../../../static/new_images/partners/mobile/m-moto.png';
import fiveHundredImgMobile from '../../../../static/new_images/partners/mobile/m-500.png';
import kevinImgMobile from '../../../../static/new_images/partners/mobile/m-kevin.png';
import animocaImgMobile from '../../../../static/new_images/partners/mobile/m-animoca.png';
import ipxImgMobile from '../../../../static/new_images/partners/mobile/m-ipx.png';

const IMAGE_SIZE_MAPPING = {
  yahoo: {
    base: yahooImgMobile,
    md: yahooImgTablet,
    lg: yahooImg,
  },
  topShot: {
    base: topshotImgMobile,
    md: topshotImgTablet,
    lg: topshotImg,
  },
  mark: {
    base: markImgMobile,
    md: markImgTablet,
    lg: markImg,
  },
  motoGp: {
    base: motoImgMobile,
    md: motoImgTablet,
    lg: motoImg,
  },
  fiveHundred: {
    base: fiveHundredImgMobile,
    md: fiveHundredImgTablet,
    lg: fiveHundredImg,
  },
  kevin: {
    base: kevinImgMobile,
    md: kevinImgTablet,
    lg: kevinImg,
  },
  animoca: {
    base: animocaImgMobile,
    md: animocaImgTablet,
    lg: animocaImg,
  },
  ipx: {
    base: ipxImgMobile,
    md: ipxImgTablet,
    lg: ipxImg,
  },
};

export default IMAGE_SIZE_MAPPING;
