import React from 'react';
import { Flex, Box, Text, Image, Tag } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import I18n from '../I18n';
import Button from '../button';
import user from '../../../static/new_images/user.png';
import dev from '../../../static/new_images/dev.png';
import largeBgImg from '../../../static/new_images/products-bg-lg.png';
import mediumBgImg from '../../../static/new_images/products-bg-md.png';
import baseBgImg from '../../../static/new_images/products-bg-base.png';
import CustomLink from '../customLink';
import download from '../../utils/download';

const Products = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '40px', md: '120px' }}
    pb={{ base: '80px', lg: '120px' }}
    borderRadius={{ base: '30px', md: '60px' }}
    bg="linear-gradient(180deg, #E2F3FF 14.58%, rgba(252, 252, 252, 0.00) 100%)"
    position="relative"
    _after={{
      display: 'block',
      content: "''",
      width: '100%',
      height: '100%',
      backgroundImage: {
        base: baseBgImg,
        md: mediumBgImg,
        lg: largeBgImg,
      },
      bgRepeat: 'no-repeat',
      backgroundPosition: { base: 'bottom center', md: 'bottom right', lg: 'top center' },
      backgroundSize: 'contain',
      borderRadius: { base: '30px', md: '60px' },
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -10,
    }}
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Box width={{ md: '555px' }}>
        <Text
          color="icon.text"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
          mb={{ base: 'space.s', lg: 'space.xl' }}
        >
          <I18n id="blocto.index.products.title" />
        </Text>
        <Text
          color="icon.text"
          fontSize={{ md: '25px' }}
          fontWeight={{ md: 'weight.m' }}
          lineHeight={{ base: '130%', md: '120%' }}
        >
          <I18n id="blocto.index.products.content" />
        </Text>
      </Box>
      <Flex flexDirection={{ base: 'column', lg: 'row' }} mt={{ base: '40px', md: '80px' }}>
        <Box
          onClick={() => download('product_exploration_block')}
          flex="1"
          mr={{ lg: 'space.xl' }}
          mb={{ base: 'space.s', md: 'space.xl', lg: '0' }}
          cursor="pointer"
        >
          <ProductCard
            imgSrc={user}
            tag={<I18n id="blocto.index.products.card1.tag" />}
            title={<I18n id="blocto.index.products.card1.title" />}
            content={<I18n id="blocto.index.products.card1.content" />}
            buttonText={<I18n id="blocto.index.products.card1.button" />}
          />
        </Box>
        <CustomLink
          to="https://docs.blocto.app/blocto-sdk/"
          gtag={{
            event: 'bloctoweb_click_sdkpage',
            properties: { location: 'product_exploration_block' },
          }}
          isExternal
          noHoverStyle
          flex="1"
        >
          <ProductCard
            imgSrc={dev}
            tag={<I18n id="blocto.index.products.card2.tag" />}
            title={<I18n id="blocto.index.products.card2.title" />}
            content={<I18n id="blocto.index.products.card2.content" />}
            buttonText={<I18n id="blocto.index.products.card2.button" />}
          />
        </CustomLink>
      </Flex>
    </Box>
  </Box>
);

export default Products;

const ProductCard = ({ imgSrc, tag, title, content, buttonText, ...rest }) => (
  <Box
    borderRadius="20px"
    overflow="hidden"
    _hover={{
      '> div:first-of-type': {
        background: 'background.primary',
      },
    }}
    {...rest}
  >
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      height={{ md: '277px' }}
      p="space.xl"
      background="rgba(255, 255, 255, 0.50)"
      backdropFilter="blur(50px)"
      transition="0.5s background ease-in-out"
    >
      <Image
        src={imgSrc}
        width={{ base: '100px', md: '160px' }}
        height={{ base: '100px', md: '160px' }}
        mr="space.xl"
        mb={{ base: 'space.xl', md: '0' }}
      />
      <Box width={{ md: '321px' }}>
        <Tag variant="blue">{tag}</Tag>
        <Text
          color="icon.text"
          fontSize={{ base: '20px', md: '31px' }}
          fontWeight="weight.m"
          lineHeight="120%"
          my="space.s"
        >
          {title}
        </Text>
        <Text
          color="icon.text"
          fontSize={{ base: '16px', md: '20px' }}
          lineHeight={{ base: '130%', md: '140%' }}
        >
          {content}
        </Text>
      </Box>
    </Flex>
    <Flex
      justifyContent="flex-end"
      px="space.m"
      py="space.s"
      background="rgba(186, 224, 255, 0.40)"
      backdropFilter="blur(50px)"
    >
      <Button variant="plain" withArrowIcon noHoverStyle>
        {buttonText}
      </Button>
    </Flex>
  </Box>
);

ProductCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};
