import React from 'react';
import { Flex, Text, Box, Grid } from '@chakra-ui/react';
import I18n from '../I18n';
import largeBannerImg from '../../../static/new_images/banner-lg.png';
import mediumBannerImg from '../../../static/new_images/banner-md.png';
import baseBannerImg from '../../../static/new_images/banner.png';
import { AnimationButton } from '../button/index';
import download from '../../utils/download';
import gtagEvent from '../../utils/gtag';

const Banner = () => (
  <Flex
    backgroundImage={{
      base: baseBannerImg,
      md: mediumBannerImg,
      lg: largeBannerImg,
    }}
    height={{ base: '800px', md: '1024px', lg: '810px', '2xl': '1080px' }}
    width="100%"
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '142px', md: '248px', lg: '112px' }}
    pb="40px"
    bgRepeat="no-repeat"
    backgroundPosition={{
      base: 'bottom right',
      lg: 'bottom 0 right -20%',
      xl: 'bottom 50% right -15%',
      '2xl': 'bottom 50% right -10%',
    }}
    backgroundSize={{ base: '90%', md: '50%', lg: '700px', xl: '850px', '2xl': '1250px' }}
  >
    <Flex
      flexDirection="column"
      justifyContent={{ base: 'flex-start', lg: 'center' }}
      width="100%"
      maxWidth="1200px"
      margin="0 auto"
    >
      <Box w={{ base: '360px', md: '620px' }}>
        <Grid
          fontSize={{ base: '44px', md: '72px' }}
          fontWeight="weight.m"
          lineHeight={{ base: '120%', md: '110%' }}
        >
          <Box>
            <I18n id="blocto.index.banner.title" />
          </Box>
          <Box color="font.highlight">
            <I18n id="blocto.index.banner.titleBlue" />
          </Box>
        </Grid>
        <Text
          fontSize={{ base: '16px', md: '20px' }}
          lineHeight={{ base: '130%', md: '140%' }}
          mt="space.s"
          mb="space.xl"
        >
          <I18n id="blocto.index.banner.content" />
        </Text>
      </Box>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
      >
        <AnimationButton
          mr="space.2xs"
          mb={{ base: 'space.s', md: '0' }}
          onClick={() => download('main_feature_block')}
        >
          <I18n id="blocto.index.banner.button1" />
        </AnimationButton>

        <AnimationButton
          variant="primary"
          onClick={() => {
            gtagEvent('bloctoweb_click_sdkpage', {
              location: 'main_feature_block',
            });
            window.location.href = '/sdk';
          }}
        >
          <I18n id="blocto.index.banner.button2" />
        </AnimationButton>
      </Flex>
    </Flex>
  </Flex>
);

export default Banner;
