import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Flex, Box, Text, Tag } from '@chakra-ui/react';
import I18n from '../I18n';

const quantifyData = [
  {
    number: '1.7',
    units: [{ value: 'M', position: 'right' }],
    content: 'blocto.index.milestone.data1',
  },
  {
    number: '100',
    units: [{ value: '+', position: 'right' }],
    content: 'blocto.index.milestone.data2',
  },
  {
    number: '370',
    units: [{ value: 'M', position: 'right' }],
    content: 'blocto.index.milestone.data3',
  },
  {
    number: '35',
    units: [
      { value: '< ', position: 'left' },
      { value: 's', position: 'right' },
    ],
    content: 'blocto.index.milestone.data4',
  },
];

const Milestone = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '40px', md: '80px' }}
    pb={{ base: '60px', md: '80px' }}
    bg="border.highlight"
    borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
    position="relative"
    _after={{
      display: 'block',
      content: '""',
      bg: 'border.highlight',
      width: '100%',
      height: { base: '20px', md: '40px' },
      position: 'absolute',
      bottom: { base: '-20px', md: '-40px' },
      left: '0',
      zIndex: '-1',
    }}
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Tag variant="white">
        <I18n id="blocto.index.milestone" />
      </Tag>
      <Text
        color="font.inverse"
        fontSize={{ base: '24px', md: '49px' }}
        fontWeight="weight.m"
        lineHeight="120%"
        width={{ base: '100%', md: '640px', lg: '829px', xl: '880px' }}
        mt={{ base: '12px', md: '24px' }}
        mb={{ base: '40px', md: '80px' }}
      >
        <I18n id="blocto.index.milestone.title" />
      </Text>
      <Flex flexWrap="wrap">
        {quantifyData.map((item, index) => (
          <Box
            key={item.content}
            width={{ base: '50%', lg: '25%' }}
            px={{ base: '0 5px', lg: '60px' }}
            mb={{ base: [0, 1].includes(index) ? '44px' : '0', lg: '0' }}
            color="font.inverse"
            textAlign="center"
            position="relative"
            _after={{
              display: {
                base: [0, 2].includes(index) ? 'block' : 'none',
                lg: [0, 1, 2].includes(index) ? 'block' : 'none',
              },
              content: '""',
              width: '0.5px',
              height: '73px',
              bg: 'background.primary',
              position: 'absolute',
              left: '100%',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Flex
              w="100%"
              justify="center"
              fontSize={{ base: '20px', md: '49px' }}
              fontWeight="weight.m"
              whiteSpace="pre"
            >
              {item.units
                .filter((unit) => unit.position === 'left')
                .map((unit, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text key={i} as="span">
                    {unit.value}
                  </Text>
                ))}
              <Text as="span">
                <CountUp
                  duration={1}
                  end={item.number}
                  decimals={item.number.includes('.') ? 1 : 0}
                  scrollSpyOnce
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </Text>
              {item.units
                .filter((unit) => unit.position === 'right')
                .map((unit, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text key={i} as="span">
                    {unit.value}
                  </Text>
                ))}
            </Flex>
            <Text
              lineHeight={{ base: '150%', md: '130%' }}
              fontSize={{ base: '10px', md: '16px' }}
              opacity="0.8"
              maxWidth={{ base: '120px', md: '174px' }}
              margin="0 auto"
            >
              <I18n id={item.content} />
            </Text>
          </Box>
        ))}
      </Flex>
    </Box>
  </Box>
);

export default Milestone;
