import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import I18n from '../../I18n';
import CTA_DATA from './intergated.const';
import CtaBottom from '../../ctaBottom';
import SubscribeEmail from '../../ctaBottom/subscribeEmail';
import ChainCarousel from '../../chainCarousel';

const Integrated = () => (
  <Box px={{ base: '24px', md: '64px', lg: '120px' }} py={{ base: '80px', lg: '160px' }}>
    <Box w="100%" maxW="1200px" m="0 auto">
      <Text
        mb={{ base: 'space.m', md: 'space.4xl', lg: 'space.5xl' }}
        color="icon.text"
        fontSize={{ base: '32px', md: '49px', lg: '51px' }}
        fontWeight="weight.m"
        lineHeight={{ base: '120%', lg: '64px' }}
      >
        <I18n id="blocto.index.integratedWith" />
      </Text>

      <ChainCarousel mb={{ base: '64px', md: '80px', lg: '96px' }} />

      <CtaBottom data={CTA_DATA}>
        <SubscribeEmail />
      </CtaBottom>
    </Box>
  </Box>
);

export default Integrated;
