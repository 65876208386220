import React from 'react';
import { Flex, Box, Text, Tag } from '@chakra-ui/react';
import I18n from '../I18n';
import { AnimationButton } from '../button';
import Card from '../card';
import download from '../../utils/download';
import interaction from '../../../static/new_images/interaction.png';
import padlock from '../../../static/new_images/padlock.png';

const Features = () => (
  <>
    <Box
      height={{ base: '24px', md: '40px' }}
      bg="background.primary"
      borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
    />
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '40px', md: '80px' }}
      pb={{ base: '80px', lg: '160px' }}
      bg="background.primary"
    >
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
        w="100%"
        maxW="1200px"
        m="0 auto"
        position="relative"
      >
        <Box mb={{ base: '40px', md: '80px', lg: '0' }}>
          <Box
            width={{ base: '100%', lg: '470px' }}
            position={{ base: 'static', lg: 'sticky' }}
            top={{ base: '110px', md: '112px', lg: '136px' }}
          >
            <Tag variant="blue">
              <I18n id="blocto.index.features" />
            </Tag>
            <Text
              color="icon.text"
              fontSize={{ base: '32px', md: '49px' }}
              fontWeight="weight.m"
              lineHeight="120%"
              my={{ base: 'space.s', lg: 'space.xl' }}
            >
              <I18n id="blocto.index.features.title" />
            </Text>
            <Text
              color="icon.text"
              fontSize={{ md: '25px' }}
              fontWeight={{ md: 'weight.m' }}
              lineHeight={{ base: '130%', md: '120%' }}
              mb={{ base: 'space.s', lg: 'space.xl' }}
            >
              <I18n id="blocto.index.features.content" />
            </Text>
            <AnimationButton onClick={() => download('introduction_block')}>
              <I18n id="blocto.index.features.button" />
            </AnimationButton>
          </Box>
        </Box>
        <Box maxWidth={{ lg: '470px' }}>
          <Box width="100%">
            <Card
              color="blue"
              imgSrc={interaction}
              title={<I18n id="blocto.index.features.card1.title" />}
              content={<I18n id="blocto.index.features.card1.content" />}
              mb="space.xl"
              width={{ base: '100%', lg: 'min(470px, 100%)' }}
            />
            <Card
              color="darkBlue"
              imgSrc={padlock}
              title={<I18n id="blocto.index.features.card2.title" />}
              content={<I18n id="blocto.index.features.card2.content" />}
              width={{ base: '100%', lg: 'min(470px, 100%)' }}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  </>
);

export default Features;
