/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Flex, Image, Text, Box } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import Button from '../button';
import CustomLink from '../customLink';
import PageArrow from '../svgIcon/pageArrow';
import gtagEvent from '../../utils/gtag';

import batchImg from '../../../static/new_images/newsSwiper/batch-tx.jpg';
import hookathonImg from '../../../static/new_images/newsSwiper/hookathon.png';
import huntImg from '../../../static/new_images/newsSwiper/hunt.png';

const FeatureCardInfo = [
  {
    img: batchImg,
    title: 'Blocto Batch Transaction 🚨',
    content:
      'Ever wished managing your transactions could be quicker, more convenient, and cost-effective?Batch transactions not only save you time but also put some extra crypto back in your pocket. Don’t miss out on this opportunity to revolutionize your crypto journey—check out this website now and embrace a smarter, more efficient way to navigate the world of digital assets!',
    link: 'https://lihi2.com/vhgo9?utm_source=website&utm_medium=banner',
  },
  {
    img: hookathonImg,
    title: '<span>Hookathon</span> Powered by Blocto 💡',
    content:
      'Calling all creative devs and Web3 enthusiasts! Join the Hookathon Powered by Blocto for a chance to win $1000 and present your project at AAvolution House Istanbul! Dive into Web3 innovation, redefine the future, and sign up now! 🏃‍♂️',
    link: 'https://www.notion.so/portto/Hookathon-Powered-by-Blocto-528ef012bb9a43ef834f6007c2177613',
  },
  {
    img: huntImg,
    title: 'Blocto <span>Hunt</span> 🕵️‍♂️',
    content:
      'Join our Blocto treasure hunt! Explore our website homepage and uncover the hidden key for a chance to enter a lucky draw and win a secret gift. (Hint: Highlight to reveal hidden clues) Ready for some fun? Start highlighting now 🔍',
  },
];

const News = () => {
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const prev = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'carousel_block',
    });
    swiper.slideTo(swiperIndex - 1);
  };
  const nexto = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'carousel_block',
    });
    swiper.slideTo(swiperIndex + 1);
  };

  return (
    <Box px={{ base: '24px', md: '64px', lg: '120px' }} py="80px" bg="#FCFCFC">
      <Box w="100%" maxW="1200px" m="0 auto">
        <Swiper
          autoHeight
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay]}
          onSwiper={(s) => {
            setSwiper(s);
          }}
          onSlideChange={(e) => {
            setSwiperIndex(e.realIndex);
          }}
        >
          {FeatureCardInfo.map((item) => (
            <SwiperSlide key={item.content} autoPlay>
              <Flex
                flexDirection={{ base: 'column', lg: 'row' }}
                justifyContent="space-between"
                alignItems={{ base: 'flex-start', lg: 'center' }}
                px={{ base: 'space.xl', lg: 'space.xl' }}
                pt={{ base: '54px', lg: '60px' }}
                pb={{ base: 'space.xl', lg: '60px' }}
                bg="border.tertiary"
                borderRadius={{ base: '30px', lg: '60px' }}
              >
                <Image
                  src={item.img}
                  width={{ base: '160px', lg: '320px' }}
                  height={{ base: '160px', lg: '320px' }}
                  ml={{ base: '0', lg: '60px' }}
                  mr={{ base: '30px', lg: '0' }}
                  mb={{ base: '30px', lg: '0' }}
                  borderRadius={{ base: '14px', lg: '30px' }}
                />

                <Box
                  width={{ base: '100%', lg: 'min(460px, 100%)' }}
                  mt={{ base: 'space.xl', lg: '0' }}
                >
                  <Text
                    color="icon.text"
                    fontSize={{ base: '32px', md: '39px' }}
                    fontWeight={{ base: 'weight.m', md: 'weight.s' }}
                    lineHeight="120%"
                    mb={{ base: 'space.s', md: 'space.xl' }}
                    maxWidth={{ lg: '400px' }}
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    sx={{
                      '> span': {
                        color: 'font.highlight',
                      },
                    }}
                  />
                  <Text
                    color="icon.text"
                    fontSize="size.body.2"
                    lineHeight="130%"
                    mb="space.xl"
                    maxWidth={{ lg: '400px' }}
                  >
                    {item.content}
                  </Text>

                  {item.link && (
                    <CustomLink
                      noHoverStyle
                      to={item.link}
                      gtag={{
                        event: 'bloctoweb_click_link',
                        properties: {
                          location: 'carousel_block',
                          topic_title: item.title,
                          link_url: item.link,
                          link_domain: new URL(item.link).host,
                        },
                      }}
                      isExternal
                      display="block"
                    >
                      <Button
                        size="sm"
                        variant="plain"
                        withArrowIcon
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight="130%"
                      >
                        Learn more
                      </Button>
                    </CustomLink>
                  )}
                </Box>
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>

        <Flex w="100%" justify="flex-end" p={{ base: '12px 24px', md: 'space.xl' }} display="flex">
          <Button
            mode="icon"
            variant="icon"
            mr="space.m"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={prev}
            disabled={swiperIndex === 0}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 0 ? '#BFC2C6' : undefined} transform="scaleX(-1)" />
          </Button>
          <Button
            mode="icon"
            variant="icon"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={nexto}
            disabled={swiperIndex === 2}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 2 ? '#BFC2C6' : undefined} />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default News;
