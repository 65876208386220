import React from 'react';
import { Box, Grid, GridItem, Image, Text, useBreakpointValue, Tag } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import I18n from '../../I18n';

import CustomLink from '../../customLink';
import IMAGE_SIZE_MAPPING from './partners.const';
import PartnerIcons from '../../partnerIcons';

const Partners = () => {
  const size = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg' });
  return (
    <Box px={{ base: '24px', md: '64px', lg: '120px' }} py={{ base: '80px', md: '160px' }}>
      <Box w="100%" maxW="1200px" m="0 auto">
        <Box w={{ base: '100%', lg: '566px' }}>
          <Tag variant="blue">
            <I18n id="blocto.index.partners" />
          </Tag>
          <Text
            mt={{ base: 'space.s', lg: 'space.xl' }}
            mb={{ base: 'space.m', lg: 'space.xl' }}
            color="icon.text"
            align="left"
            fontSize={{ base: '32px', sm: '49px' }}
            fontWeight="weight.m"
            lineHeight="120%"
            sx={{
              '@media screen and (min-width: 769px)': {
                fontSize: '51px',
                lineHeight: '64px',
              },
            }}
          >
            <I18n id="blocto.index.partners.title" />
          </Text>
          <Text
            color="icon.text"
            fontSize={{ base: '16px', md: '20px' }}
            lineHeight={{ base: '150%', md: '140%' }}
            maxWidth={{ base: '310px', md: '518px', lg: '85%' }}
          >
            <I18n id="blocto.index.partners.content" />
          </Text>
        </Box>

        <Grid
          height={{ lg: '863px' }}
          my={{ base: '40px', lg: '80px' }}
          templateColumns={{ base: null, lg: 'repeat(4, 1fr)' }}
          gridAutoRows={{ lg: '87px' }}
          gridAutoColumns={{ base: '50%', lg: 'unset' }}
        >
          <GridItem
            rowStart={{ base: 1, lg: 4 }}
            rowEnd={{ base: 1, lg: 10 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mt={{ base: '0', lg: 'space.s' }}
            ml={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="center"
          >
            <ImageWrap to="https://nft-store.blocto.app/yahoo/flow">
              <Image src={IMAGE_SIZE_MAPPING.yahoo[size]} alt="yahoo" width="100%" />
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={2}
            rowStart={1}
            rowEnd={{ base: 1, lg: 6 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mt={{ base: '0', md: '0' }}
            mr={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="flex-end"
          >
            <ImageWrap to="https://markcubancompanies.com/blockchain/">
              <Image src={IMAGE_SIZE_MAPPING.mark[size]} alt="mark" width="100%" />
              <Box
                opacity="0"
                position="absolute"
                bottom={{ base: '7%', md: 'space.s', lg: '4%' }}
                left={{ base: '7%', md: 'space.s', lg: '8%' }}
                transition="0.3s opacity ease-in-out"
              >
                <Text
                  fontSize={{ base: '14px', md: '31px' }}
                  fontWeight="weight.m"
                  lineHeight={{ base: '20px', md: '120%' }}
                  color="font.inverse"
                >
                  Mark Cuban
                </Text>
                <Text
                  fontSize={{ base: '10px', md: '16px' }}
                  lineHeight={{ base: '15px', md: '130%' }}
                  color="font.inverse"
                >
                  Advisor & Strategic Investor
                </Text>
              </Box>
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={{ base: 1, lg: 2 }}
            rowStart={{ base: 2, lg: 6 }}
            rowEnd={{ base: 2, lg: 11 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mb={{ lg: '0' }}
            ml={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="flex-start"
          >
            <ImageWrap to="https://motogp-ignition.com/">
              <Image src={IMAGE_SIZE_MAPPING.motoGp[size]} alt="moto" width="100%" />
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={{ base: 2, lg: 3 }}
            rowStart={{ base: 2, lg: 1 }}
            rowEnd={{ base: 2, lg: 4 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mt={{ lg: '0' }}
            mr={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="flex-end"
          >
            <ImageWrap to="https://500.co/">
              <Image src={IMAGE_SIZE_MAPPING.fiveHundred[size]} alt="500" width="100%" />
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={{ base: 1, lg: 3 }}
            rowStart={{ base: 3, lg: 4 }}
            rowEnd={{ base: 3, lg: 8 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            ml={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="center"
            sx={{
              '@media screen and (min-width: 1200px) and (max-width: 1300px)': {
                gridRowEnd: '7',
              },
            }}
          >
            <ImageWrap noHoverStyle to="https://www.linkedin.com/in/kevinchou/" isExternal>
              <Image src={IMAGE_SIZE_MAPPING.kevin[size]} alt="kevin" width="100%" />
              <Box
                opacity="0"
                position="absolute"
                bottom={{ base: '7%', md: 'space.s', lg: '4%' }}
                left={{ base: '7%', md: 'space.s', lg: '8%' }}
                transition="0.3s opacity ease-in-out"
              >
                <Text
                  fontSize={{ base: '14px', md: '31px' }}
                  fontWeight="weight.m"
                  lineHeight={{ base: '20px', md: '120%' }}
                  color="font.inverse"
                >
                  Kevin Chou
                </Text>
                <Text
                  fontSize={{ base: '10px', md: '16px' }}
                  lineHeight={{ base: '15px', md: '130%' }}
                  color="font.inverse"
                >
                  Advisor & Strategic Investor
                </Text>
              </Box>
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={{ base: 2, lg: 3 }}
            rowStart={{ base: 3, lg: 8 }}
            rowEnd={{ base: 3, lg: 11 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mb={{ lg: '0' }}
            mr={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="flex-start"
            sx={{
              '@media screen and (min-width: 1200px) and (max-width: 1300px)': {
                gridRowStart: '7',
              },
            }}
          >
            <ImageWrap to="https://nbatopshot.com/">
              <Image src={IMAGE_SIZE_MAPPING.topShot[size]} alt="topshot" width="100%" />
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={{ base: 1, lg: 4 }}
            rowStart={{ base: 4, lg: 1 }}
            rowEnd={{ base: 4, lg: 6 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mt={{ base: 'space.4xs', lg: '0' }}
            mb={{ base: '0', lg: 'space.s' }}
            ml={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="flex-end"
          >
            <ImageWrap to="https://www.animocabrands.com/mission-and-vision">
              <Image src={IMAGE_SIZE_MAPPING.animoca[size]} alt="animoca" width="100%" />
            </ImageWrap>
          </GridItem>
          <GridItem
            colStart={{ base: 2, lg: 4 }}
            rowStart={{ base: 4, lg: 6 }}
            rowEnd={{ base: 4, lg: 11 }}
            m={{ base: 'space.4xs', md: 'space.s' }}
            mb={{ base: '0', md: '0' }}
            mr={{ base: '0', lg: 'space.s' }}
            display="flex"
            alignItems="flex-start"
          >
            <ImageWrap to="https://www.ipxcorp.com">
              <Image src={IMAGE_SIZE_MAPPING.ipx[size]} alt="IPX" width="100%" />
            </ImageWrap>
          </GridItem>
        </Grid>

        <PartnerIcons />
      </Box>
    </Box>
  );
};

export default Partners;

const ImageWrap = ({ to, children, ...rest }) => (
  <CustomLink
    noHoverStyle
    isExternal
    to={to}
    gtag={{
      event: 'bloctoweb_click_partner',
      properties: {
        link_url: to,
        link_domain: new URL(to).host,
      },
    }}
    borderRadius="24px"
    overflow="auto"
    w="100%"
    cursor="pointer"
    position="relative"
    transition="0.3s box-shadow ease-in-out"
    _hover={{
      boxShadow: { lg: '0px 14px 34px 0px rgba(0, 0, 0, 0.35)' },
      '> div': {
        opacity: '1',
      },
    }}
    {...rest}
  >
    {children}
  </CustomLink>
);

ImageWrap.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mb: PropTypes.object,
};

ImageWrap.defaultProps = {
  children: null,
  mb: { base: '0', md: '0' },
};
